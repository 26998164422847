// Axios GLOBAL configuration
import axios from 'axios'

import { getAuthToken, history, removeAuthToken } from '../helpers'

axios.interceptors.request.use(
  config => {
    const token = getAuthToken()

    config.headers = { Authorization: `Bearer ${token}` }

    const language = localStorage.getItem('i18nextLng')

    if (language) {
      config.headers['Accept-Language'] = language
    }

    return config
  },
  error =>
    // Do something with request error
    Promise.reject(error)
)

axios.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      removeAuthToken()
      history.push('/login')
    }
    // Do something with response error
    return Promise.reject(error)
  }
)

axios.defaults.baseURL = process.env.REACT_APP_HOST
